<template>
    <transition>
        <form name="KSPayWeb" method="get" action="api/plugins/onflatpay" >
        <table border=0 width=100%>
            <tr style="display: none;">
            <td>
            <hr noshade size=1>
            <b>온플랫 결제</b>
            <hr noshade size=1>
            </td>
            </tr>
        </table>
        <br>
        <table border=0 width=100%>
        <tr>
        <td align=center>
        <table width=100% cellspacing=0 cellpadding=0 border=0>
        <tr>
        <td>
        <table width=100% cellspacing=1 cellpadding=2 border=0>
        <tr bgcolor=#ec9c05 height=25>
        <td align=center><font color="#FFFFFF">
        정보를 확인 하신 후 지불버튼을 눌러주십시오
        </font></td>
        </tr>
        <tr bgcolor=#FFFFFF>
        <td valign=top>
        <table width=100% cellspacing=0 cellpadding=2 border=0>
        <tr>
        <td align=center>
        <br>
        <table>
        <tr style="display: none;">
        <!----------------------------------------------- < Part 2. 고객에게 보여지지 않는 항목 > ------------------------------------>
        <!--이부분은 결제를 위해 상점에서 기본정보를 세팅해야 하는 부분입니다.														-->
        <!--단 고객에게는 보여지면 안되는 항목이니 테스트 후 필히 hidden으로 변경해주시길 바랍니다.									-->
        <td colspan=2>고객에게 보여지지 않아야 하는 설정값 항목</td>
        </tr>
        <tr style="display: none;">
            <td>결제수단 : </td>
            <td>
                <select name=sndPaymethod>
                    <option value="1000000000" selected>신용카드</option>
                        <option value="0100000000">가상계좌</option>
                        <option value="0010000000">계좌이체</option>
                        <option value="0000010000" >휴대폰결제</option>
                </select>
            </td>
        </tr>
        <tr style="display: none;">
            <td>상점아이디 : </td>
            <td><input type=text name="sndStoreid" size="10" maxlength="10" :value="sndStoreid"></td></tr>
        <tr style="display: none;">
        <!-- 화폐단위 원화로 설정 : 410 또는 WON -->
            <td>화폐단위 : </td>
            <td><input type=text name=sndCurrencytype  size=30 maxlength=3 value="WON"></td>
        </tr>
        <tr>
        <!--상품명은 30Byte(한글 15자) 입니다. 특수문자 ' " - ` 는 사용하실수 없습니다. 따옴표,쌍따옴표,빼기,백쿼테이션 -->
            <td>주문번호 : </td>
            <td><input type=text name=sndOrdernumber  size=30 maxlength=30 :value="sndOrdernumber" readonly style="border:none;border-right:0px; border-top:0px; boder-left:0px; boder-bottom:0px;"></td>
        </tr>
        <tr style="display: none;">
        <!--주민등록번호는 필수값이 아닙니다.-->
            <td>주민번호 : </td>
            <td><input type=text name=sndAllregid  size=30 maxlength=13 value="">
        </td>
        </tr>
        <tr style="display: none;">
            <td colspan=2><hr></td></tr>
        <tr style="display: none;">
            <td colspan=2>신용카드 기본항목</td>
        </tr>
        <tr style="display: none;">
        <!--상점에서 적용할 할부개월수를 세팅합니다. 여기서 세팅하신 값은 KSPAY결재팝업창에서 고객이 스크롤선택하게 됩니다 -->
        <!--아래의 예의경우 고객은 0~12개월의 할부거래를 선택할수있게 됩니다. -->
            <td>할부개월수  : </td>
            <td><input type=text name=sndInstallmenttype size=30 maxlength=30 value="ALL(0:2:3:4:5:6:7:8:9:10:11:12:13:14:15:16:17:18:19:20:21:22:23:24:25:26:27:28:29:30:31:32:33:34:35:36)"></td>
        </tr>
        <tr style="display: none;">
        <!--무이자 구분값은 중요합니다. 무이자 선택하게 되면 상점쪽에서 이자를 내셔야합니다.-->
        <!--무이자 할부를 적용하지 않는 업체는 value='NONE" 로 넘겨주셔야 합니다. -->
        <!--예 : 모두 무이자 적용할 때는 value="ALL" / 무이자 미적용할 때는 value="NONE" -->
        <!--예 : 3,4,5,6개월 무이자 적용할 때는 value="3:4:5:6" -->
            <td>무이자구분  : </td>
            <td><input type=text name=sndInteresttype  size=30 maxlength=30 value="NONE"></td>
        </tr>
        <tr style="display: none;">
            <td>신용카드표시구분  : </td>
            <td><input type=text name=sndShowcard  size=30 maxlength=30 value="C"></td>
        </tr>
        <tr>
            <td colspan=2><hr></td></tr>
        <tr style="display: none;">
        <!----------------------------------------------- <Part 3. 고객에게 보여주는 항목 > ----------------------------------------------->
            <td colspan=2>고객에게 보여주는 항목</td>
        </tr>
        <tr>
        <!--상품명은 30Byte(한글 15자)입니다. 특수문자 ' " - ` 는 사용하실수 없습니다. 따옴표,쌍따옴표,빼기,백쿼테이션 -->
            <td>상품명 : </td>
            <td><input type=text name=sndGoodname  size=30 maxlength=30 :value="sndGoodname" readonly style="border:none;border-right:0px; border-top:0px; boder-left:0px; boder-bottom:0px;"></td>
        </tr>
        <tr>
            <td>가격 : </td>
            <td><input type=text name=sndAmount  size=30 maxlength=9 :value="sndAmount" readonly style="border:none;border-right:0px; border-top:0px; boder-left:0px; boder-bottom:0px;"></td>
        </tr>
        <tr>
            <td>성명 : </td>
            <td><input type=text name=sndOrdername  size=30 maxlength=20 :value="sndOrdername" readonly style="border:none;border-right:0px; border-top:0px; boder-left:0px; boder-bottom:0px;"></td>
        </tr>
        <!--KSPAY에서 결제정보를 메일로 보내줍니다.(신용카드거래에만 해당)-->
        <tr>
            <td>전자우편 : </td>
            <td>
            <input type=text name=sndEmail  size=30 maxlength=50 value="" placeholder="영수증을 email로 받으려면 작성하세요.">
            </td>
        </tr>	
        <!--카드사에 SMS 서비스를 등록하신 고객에 한해서 SMS 문자메세지를 전송해 드립니다.-->
        <!--전화번호 value 값에 숫자만 넣게 해주시길 바랍니다. : '-' 가 들어가면 안됩니다.-->
        <tr>
            <td>이동전화 : </td>
            <td>
            <input type=text name=sndMobile  size=30 maxlength=12 :value=sndMobile readonly style="border:none;border-right:0px; border-top:0px; boder-left:0px; boder-bottom:0px;" placeholder="숫자만 기입해주세요.">
            </td>
        </tr>

        <tr>
            <td colspan=2 align=center>
            <br>
            <v-btn @click="_pay()"></v-btn>
            <!-- <input type="button" id="subBtn" value=" 결 제 " onClick="javascript:_pay(document.KSPayWeb);"> -->
            <br><br>
            </td>
        </tr>
        </table>
        </td>
        </tr>
        </table>
        </td>
        </tr>
        </table>
        </td>
        </tr>
        </table>
        </td>
        </tr>
        </table>
        <br>

        <table border=0 width=100%>
            <tr>
            <td><hr noshade size=1></td>
            </tr>
            <input type=hidden name=sndCharSet value="utf-8">									<!-- 가맹점 문자열 체어셋 -->
            <input type=hidden name=sndReply value="">
            <input type=hidden name=sndEscrow value="0">                           <!--에스크로적용여부-- 0: 적용안함, 1: 적용함 -->
            <input type=hidden name=sndVirExpDt value="">                            <!-- 마감일시 -->
            <input type=hidden name=sndVirExpTm value="">                            <!-- 마감시간 -->
            <input type=hidden name=sndStoreName value="온플랫(주)">                  <!--회사명을 한글로 넣어주세요(최대20byte)-->
            <input type=hidden name=sndStoreNameEng value="ONPALT">                       <!--회사명을 영어로 넣어주세요(최대20byte)-->
            <input type=hidden name=sndStoreDomain value="https://dev-api.on-plat.com"> <!-- 회사 도메인을 http://를 포함해서 넣어주세요-->
            <input type=hidden name=sndGoodType	value="1">								           <!--실물(1) / 디지털(2) -->
            <input type=hidden name=sndUseBonusPoint value="">   							           <!-- 포인트거래시 60 -->                                                                                                                                                           
            <input type=hidden name=sndRtApp value="">									           <!-- 하이브리드APP 형태로 개발시 사용하는 변수 -->
            <input type=hidden name=sndStoreCeoName value="">                            <!--  카카오페이용 상점대표자명 --> 
            <input type=hidden name=sndStorePhoneNo value="">                            <!--  카카오페이 연락처 --> 
            <input type=hidden name=sndStoreAddress value="">                            <!--  카카오페이 주소 --> 
        </table>

        </form>
    </transition>
</template>

<script>

import api from "@/api";
import axios from "axios";

export default {
    data() {
        return {
            sndStoreid: 495,
            sndOrdernumber: 0,
            sndGoodname: "구매자명",
            sndOrdername: "거래번호",
            sndMobile: 1234567891,
            sndAmount: 1000, //결제금액
            pgInfoId: null, //pg정보조회 시 아이디
            productName: "상품명",
            customerName: "구매자명",
            cardNum: "0000000000000000",
            sndInstallmenttype: "00", //할부개월수
            expiryDate: "0000", //카드 유효기간
            password2: 12, //비밀번호 앞2자리
            userInfo: "000000" //생년월일
        }
    },

    methods: {
        async _pay() {
            await axios({
                    method: "get",
                    url: `https://pro-api.pay-onplat.com/onplat/out/pgInfo?=storeId=495`,
                    headers: {
                        "Content-Type": `application/json`
                    },
                })
                .then(data => this.pgInfoId = data.data[0].id)

            let res = await api.plugins.onplatpay.post({
                pgInfoId: this.pgInfoId, 
                storeId: this.sndStoreid,
                productName: this.productName,
                customerName: this.customerName,
                customerPhone: this.sndMobile,
                totalAmount: this.sndAmount,
                cardNum: this.cardNum,
                cardInst: this.sndInstallmenttype,
                expiryDate: this.expiryDate,
                password2: this.password2,
                userInfo: this.userInfo,
                returnVal: ``
            })

            console.log(res, "datares");
        }
    }

}
</script>

<style>

</style>


