var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', [_c('form', {
    attrs: {
      "name": "KSPayWeb",
      "method": "get",
      "action": "api/plugins/onflatpay"
    }
  }, [_c('table', {
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', [_c('hr', {
    attrs: {
      "noshade": "",
      "size": "1"
    }
  }), _c('b', [_vm._v("온플랫 결제")]), _c('hr', {
    attrs: {
      "noshade": "",
      "size": "1"
    }
  })])])]), _c('br'), _c('table', {
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('tr', [_c('td', {
    attrs: {
      "align": "center"
    }
  }, [_c('table', {
    attrs: {
      "width": "100%",
      "cellspacing": "0",
      "cellpadding": "0",
      "border": "0"
    }
  }, [_c('tr', [_c('td', [_c('table', {
    attrs: {
      "width": "100%",
      "cellspacing": "1",
      "cellpadding": "2",
      "border": "0"
    }
  }, [_c('tr', {
    attrs: {
      "bgcolor": "#ec9c05",
      "height": "25"
    }
  }, [_c('td', {
    attrs: {
      "align": "center"
    }
  }, [_c('font', {
    attrs: {
      "color": "#FFFFFF"
    }
  }, [_vm._v(" 정보를 확인 하신 후 지불버튼을 눌러주십시오 ")])], 1)]), _c('tr', {
    attrs: {
      "bgcolor": "#FFFFFF"
    }
  }, [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_c('table', {
    attrs: {
      "width": "100%",
      "cellspacing": "0",
      "cellpadding": "2",
      "border": "0"
    }
  }, [_c('tr', [_c('td', {
    attrs: {
      "align": "center"
    }
  }, [_c('br'), _c('table', [_c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("고객에게 보여지지 않아야 하는 설정값 항목")])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', [_vm._v("결제수단 : ")]), _c('td', [_c('select', {
    attrs: {
      "name": "sndPaymethod"
    }
  }, [_c('option', {
    attrs: {
      "value": "1000000000",
      "selected": ""
    }
  }, [_vm._v("신용카드")]), _c('option', {
    attrs: {
      "value": "0100000000"
    }
  }, [_vm._v("가상계좌")]), _c('option', {
    attrs: {
      "value": "0010000000"
    }
  }, [_vm._v("계좌이체")]), _c('option', {
    attrs: {
      "value": "0000010000"
    }
  }, [_vm._v("휴대폰결제")])])])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', [_vm._v("상점아이디 : ")]), _c('td', [_c('input', {
    attrs: {
      "type": "text",
      "name": "sndStoreid",
      "size": "10",
      "maxlength": "10"
    },
    domProps: {
      "value": _vm.sndStoreid
    }
  })])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', [_vm._v("화폐단위 : ")]), _c('td', [_c('input', {
    attrs: {
      "type": "text",
      "name": "sndCurrencytype",
      "size": "30",
      "maxlength": "3",
      "value": "WON"
    }
  })])]), _c('tr', [_c('td', [_vm._v("주문번호 : ")]), _c('td', [_c('input', {
    staticStyle: {
      "border": "none",
      "border-right": "0px",
      "border-top": "0px",
      "boder-left": "0px",
      "boder-bottom": "0px"
    },
    attrs: {
      "type": "text",
      "name": "sndOrdernumber",
      "size": "30",
      "maxlength": "30",
      "readonly": ""
    },
    domProps: {
      "value": _vm.sndOrdernumber
    }
  })])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', [_vm._v("주민번호 : ")]), _c('td', [_c('input', {
    attrs: {
      "type": "text",
      "name": "sndAllregid",
      "size": "30",
      "maxlength": "13",
      "value": ""
    }
  })])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('hr')])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("신용카드 기본항목")])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', [_vm._v("할부개월수 : ")]), _c('td', [_c('input', {
    attrs: {
      "type": "text",
      "name": "sndInstallmenttype",
      "size": "30",
      "maxlength": "30",
      "value": "ALL(0:2:3:4:5:6:7:8:9:10:11:12:13:14:15:16:17:18:19:20:21:22:23:24:25:26:27:28:29:30:31:32:33:34:35:36)"
    }
  })])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', [_vm._v("무이자구분 : ")]), _c('td', [_c('input', {
    attrs: {
      "type": "text",
      "name": "sndInteresttype",
      "size": "30",
      "maxlength": "30",
      "value": "NONE"
    }
  })])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', [_vm._v("신용카드표시구분 : ")]), _c('td', [_c('input', {
    attrs: {
      "type": "text",
      "name": "sndShowcard",
      "size": "30",
      "maxlength": "30",
      "value": "C"
    }
  })])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('hr')])]), _c('tr', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("고객에게 보여주는 항목")])]), _c('tr', [_c('td', [_vm._v("상품명 : ")]), _c('td', [_c('input', {
    staticStyle: {
      "border": "none",
      "border-right": "0px",
      "border-top": "0px",
      "boder-left": "0px",
      "boder-bottom": "0px"
    },
    attrs: {
      "type": "text",
      "name": "sndGoodname",
      "size": "30",
      "maxlength": "30",
      "readonly": ""
    },
    domProps: {
      "value": _vm.sndGoodname
    }
  })])]), _c('tr', [_c('td', [_vm._v("가격 : ")]), _c('td', [_c('input', {
    staticStyle: {
      "border": "none",
      "border-right": "0px",
      "border-top": "0px",
      "boder-left": "0px",
      "boder-bottom": "0px"
    },
    attrs: {
      "type": "text",
      "name": "sndAmount",
      "size": "30",
      "maxlength": "9",
      "readonly": ""
    },
    domProps: {
      "value": _vm.sndAmount
    }
  })])]), _c('tr', [_c('td', [_vm._v("성명 : ")]), _c('td', [_c('input', {
    staticStyle: {
      "border": "none",
      "border-right": "0px",
      "border-top": "0px",
      "boder-left": "0px",
      "boder-bottom": "0px"
    },
    attrs: {
      "type": "text",
      "name": "sndOrdername",
      "size": "30",
      "maxlength": "20",
      "readonly": ""
    },
    domProps: {
      "value": _vm.sndOrdername
    }
  })])]), _c('tr', [_c('td', [_vm._v("전자우편 : ")]), _c('td', [_c('input', {
    attrs: {
      "type": "text",
      "name": "sndEmail",
      "size": "30",
      "maxlength": "50",
      "value": "",
      "placeholder": "영수증을 email로 받으려면 작성하세요."
    }
  })])]), _c('tr', [_c('td', [_vm._v("이동전화 : ")]), _c('td', [_c('input', {
    staticStyle: {
      "border": "none",
      "border-right": "0px",
      "border-top": "0px",
      "boder-left": "0px",
      "boder-bottom": "0px"
    },
    attrs: {
      "type": "text",
      "name": "sndMobile",
      "size": "30",
      "maxlength": "12",
      "readonly": "",
      "placeholder": "숫자만 기입해주세요."
    },
    domProps: {
      "value": _vm.sndMobile
    }
  })])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2",
      "align": "center"
    }
  }, [_c('br'), _c('v-btn', {
    on: {
      "click": function ($event) {
        return _vm._pay();
      }
    }
  }), _c('br'), _c('br')], 1)])])])])])])])])])])])])])]), _c('br'), _c('table', {
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('tr', [_c('td', [_c('hr', {
    attrs: {
      "noshade": "",
      "size": "1"
    }
  })])]), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndCharSet",
      "value": "utf-8"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndReply",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndEscrow",
      "value": "0"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndVirExpDt",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndVirExpTm",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndStoreName",
      "value": "온플랫(주)"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndStoreNameEng",
      "value": "ONPALT"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndStoreDomain",
      "value": "https://dev-api.on-plat.com"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndGoodType",
      "value": "1"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndUseBonusPoint",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndRtApp",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndStoreCeoName",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndStorePhoneNo",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "sndStoreAddress",
      "value": ""
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }